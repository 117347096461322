<template>
  <div class="time-range-setting">
    <BaseElForm>
      <BaseElFormItem label="行事曆顯示起始時間" placeholder="請選擇起始時間">
        <BaseElSelect v-model="formData.start" @change="formData.end = null">
          <BaseElSelectOption
            v-for="time in timeOptions"
            :key="time"
            :label="time"
            :value="time"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem label="行事曆顯示結束時間" placeholder="請選擇結束時間">
        <BaseElSelect v-model="formData.end" :disabled="!formData.start" @change="onRangeChange">
          <BaseElSelectOption
            v-for="time in endOptions"
            :key="time"
            :label="time"
            :value="time"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { generateTimes, timeToNumber } from '@/utils/helper'
import { FindReservationParameter } from '@/api/reservation'
import { useStorage } from '@vueuse/core'
import store from '@/store'

export default defineComponent({
  name: 'TimeRangeSetting',
  setup (props, { emit }) {
    const timeUnit = ref(60)
    const timeOffset = ref(0)
    const formData = useStorage('dailyViewSettings', {
      start: '00:00',
      end: '24:00',
    })
    const shop = computed(() => store.getters.shop)

    const timeOptions = computed(() => {
      return generateTimes(timeUnit.value, 0)
    })

    const endOptions = computed(() => {
      const start = timeToNumber(formData.start)
      return timeOptions.value.filter(t => timeToNumber(t) > start)
    })

    const findReservationParameter = async () => {
      const res = await FindReservationParameter({ shopId: shop.value })
      timeUnit.value = res.timeUnit || 60
      timeOffset.value = res.timeUnitOffset || 0
    }

    const onConfirm = () => {
      emit('confirm', formData)
      emit('close')
    }

    const onRangeChange = () => {
      setTimeout(() => {
        emit('change')
      }, 300)
    }

    onMounted(async () => {
      await findReservationParameter()
    })

    return {
      formData,
      timeOptions,
      onConfirm,
      endOptions,
      onRangeChange,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
