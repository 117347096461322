<template>
  <section class="confirm">
    <hr style="margin-bottom: 16px">
    <BaseElForm ref="form" :model="formData" :show-message="false">
      <div class="flex flex-col gap-[16px]">
        <div>
          <p class="mb-[8px]">會員備註</p>
          <BaseElInput v-model="formData.comment" type="textarea" :rows="3" maxlength="1000" show-word-limit />
        </div>
        <div>
          <p class="mb-[8px]">店家筆記</p>
          <BaseElInput v-model="formData.remark" type="textarea" :rows="3" maxlength="1000" show-word-limit />
        </div>
      </div>
    </BaseElForm>
  </section>
</template>

<script>
export default {
  name: 'BlockNote',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data: () => ({
    formData: {
      comment: '',
      remark: '',
    },
  }),
  watch: {
    formData: {
      handler () {
        this.$emit('update', this.formData)
      },
      deep: true,
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.$emit('ref', this.$refs.form)
    })
  },
}
</script>

<style scoped lang="postcss">
::v-deep .el-form-item {
  @apply mb-0;
}
</style>
