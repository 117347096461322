<template>
  <el-dialog
    title="當日預約行事曆顯示設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <TimeRangeSetting @change="$emit('cahnge')" />

    <div>
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" @click="onConfirm">
        確定
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent } from 'vue'
import TimeRangeSetting from './TimeRangeSetting.vue'

export default defineComponent({
  name: 'DisplaySettingsDialog',
  components: { TimeRangeSetting },
  setup (props, { emit }) {
    const onConfirm = () => {
      emit('confirm')
    }

    return {
      onConfirm,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
