<template>
  <BaseElForm ref="form" :model="formData" :rules="formRules" label-position="top">
    <BaseElFormItem label="會員類別">
      <BaseElRadioGroup v-model="formData.isMember" @change="changeIsMember">
        <BaseElRadio data-cy="is-member-radio-btn" :label="true">會員</BaseElRadio>
        <BaseElRadio data-cy="not-member-radio-btn" :label="false">非會員</BaseElRadio>
      </BaseElRadioGroup>
    </BaseElFormItem>
    <BaseElFormItem v-if="config.allowOrderMorePeople" label="預約人數">
      <BaseElInputNumber
        v-model="formData.peopleCount"
        data-cy="order-people-num-input"
        :min="1"
        :max="config.maxOrderMorePeopleCount"
      />
    </BaseElFormItem>
    <BaseElFormItem v-if="formData.isMember" label="會員" prop="member">
      <MemberSearch data-cy="member-select" :model.sync="formData.member" />
    </BaseElFormItem>

    <div v-if="!formData.isMember">
      <BaseElFormItem label="姓名" prop="name">
        <BaseElInput v-model="formData.name" data-cy="user-name" clearable placeholder="請輸入姓名" />
      </BaseElFormItem>
      <BaseElFormItem label="電話號碼" prop="phone">
        <BaseElInput v-model="formData.phone" data-cy="user-phone" clearable placeholder="請輸入電話號碼" />
      </BaseElFormItem>
    </div>
  </BaseElForm>
</template>

<script>
import MemberSearch from '@/components/Search/MemberSearch.vue'
import { noEmptyRules, phoneRules } from '@/validation'
import { FindMember } from '@/api/member'
import { get } from 'lodash'

export default {
  name: 'BlockMemberInfo',
  components: {
    MemberSearch,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        allowOtherReservation: false,
      }),
    },
    fixedMember: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    formData: {
      isMember: true,
      member: null,
      peopleCount: 1,
    },
    formRules: {
      member: [noEmptyRules()],
      name: [noEmptyRules()],
      phone: [noEmptyRules(), phoneRules()],
    },
  }),
  computed: {
    shopId () {
      return this.$store.getters.shop
    },
  },
  watch: {
    formData: {
      handler (data) {
        this.$emit('update', data)
      },
      deep: true,
    },
  },
  async mounted () {
    this.$nextTick(() => {
      this.$emit('ref', this.$refs.form)
    })

    if (this.fixedMember) await this.findMember(this.fixedMember)
  },
  methods: {
    changeIsMember (isMember) {
      if (!isMember) this.formData.member = null
      if (isMember) {
        this.formData.name = null
        this.formData.phone = null
      }
    },

    async findMember (memberId) {
      try {
        const member = await FindMember({
          shopId: this.shopId,
          id: memberId,
        })
        this.formData.member = member
      } catch (e) {
        this.$message.error(e.message)
      }
    },
  },
}
</script>

<style scoped lang="scss">
</style>
