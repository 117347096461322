<template>
  <div>
    <div class="font-bold text-lg text-primary-100" style="margin-bottom: 16px">新增預約</div>
    <BlockMemberInfo
      :fixedMember="queryMember"
      :config="config"
      @ref="getRef($event, 'memberInfo')"
      @update="dataUpdate($event, 'memberData')"
    />
    <BlockOtherUser
      v-if="peopleCount > 1"
      :peopleCount="peopleCount"
      @update="dataUpdate($event, 'otherUserData')"
    />
    <BlockServiceInfo
      :config="config"
      :selectUnit="selectUnit"
      :selectDate="selectDate"
      :selectPeriod="selectPeriod"
      :peopleCount="peopleCount"
      @ref="getRef($event, 'serviceInfo')"
      @update="dataUpdate($event, 'mainServiceData')"
      @unitChange="$emit('unitChange', mainServiceData.serviceUnit)"
    />
    <div v-if="canOrderOtherService">
      <div v-if="!mainServiceData.date || !mainServiceData.time" class="text-sub text-gray-100 mb-[16px]">請先選擇預約日期與時段</div>
      <BlockOtherService
        v-if="mainServiceData.date && mainServiceData.time"
        :date="mainServiceData.date"
        :config="config"
        :peopleCount="peopleCount"
        :mainServiceData="mainServiceData"
        @ref="getRef($event, 'otherService')"
        @update="dataUpdate($event, 'otherServiceData')"
      />
    </div>

    <!-- ANCHOR 訂金 -->
    <BlockDeposit
      v-if="showDepositBlock"
      :memberData="memberData"
      :orderDeposit="orderDeposit"
      :model.sync="depositPayment"
      :depositConfig="depositConfig"
      :depositPaymentConfig="depositPaymentConfig"
      @ref="getRef($event, 'deposit')"
    />
    <BlockNote
      :data="compactOrder"
      @ref="getRef($event, 'note')"
      @update="dataUpdate($event, 'noteData')"
    />

    <div class="dialog-footer">
      <div class>
        <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
        <BaseElButton v-loading="loading" type="primary" @click="createReservationOrder">
          確認
        </BaseElButton>
      </div>
    </div>
    <BaseDialog
      v-if="modal.checkoutModeWarning"
      width="600px"
      hideCancel
      @close="modal.checkoutModeWarning = false"
      @confirm="$router.push({name: 'ReservationList'})"
    >
      <div />
      您正使用「預約結帳模式」，此頁面目前尚不支援預約結帳模式預約。 <br>
      建議您前往
      <RouterLink class="text-primary-100 underline" :to="{name: 'ReservationList'}">預約列表頁</RouterLink> 進行預約建立
      <br>
    </BaseDialog>
  </div>

  <!-- <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.go(-1)"
      @confirm="createReservationOrder"
    /> -->
</template>

<script>
import reservationMixin from '@/mixin/reservation'
import { CreateReservationOrder, SmartCreateReservationOrder } from '@/api/reservation'
import BlockMemberInfo from './components/BlockMemberInfo.vue'
import BlockOtherUser from './components/BlockOtherUser.vue'
import BlockServiceInfo from './components/BlockServiceInfo.vue'
import BlockOtherService from './components/BlockOtherService.vue'
import BlockDeposit from './components/BlockDeposit.vue'
import BlockNote from './components/BlockNote.vue'
import formUtils from '@/utils/form'
import { get } from 'lodash'
import debounce from 'lodash.debounce'
import { mapGetters, mapActions } from 'vuex'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { reactive } from 'vue'
import { useReservation } from '@/use/useReservation'

export default {
  name: 'CreateReservationBlock',
  components: {
    BlockMemberInfo,
    BlockServiceInfo,
    BlockOtherService,
    BlockDeposit,
    BlockNote,
    BlockOtherUser,
    BaseDialog,
  },
  mixins: [reservationMixin],
  props: {
    selectUnit: Object,
    selectDate: [String, Date],
    selectPeriod: String,
  },
  setup () {
    const modal = reactive({
      checkoutModeWarning: false,
    })
    return { modal }
  },
  async mounted () {
    const config = await this.getShopReservationConfig({ shopId: this.shopId })
    if (config.chargeType === 'checkout') {
      this.modal.checkoutModeWarning = true
    }
  },
  methods: {
    ...mapActions('shop', [
      'getShopReservationConfig',
    ]),
    async dataUpdate (data, type) {
      if (type === 'memberData') this.memberData = data
      else if (type === 'mainServiceData') {
        this.mainServiceData = data
        this.otherServiceData = []
      } else if (type === 'otherServiceData') this.$set(this.otherServiceData, 0, data)
      else if (type === 'otherUserData') this.otherUserData = data
      else if (type === 'noteData') this.noteData = data
    },

    createReservationOrder: debounce(async function () {
      console.log('create')
      if (this.loading) return
      this.loading = true

      for (const form of Object.keys(this.form)) {
        if (this.form[form]) {
          if (!await formUtils.checkForm(this.form[form])) {
            this.$message.warning('資料輸入有誤，請檢查！')
            this.loading = false
            return
          }
        }
      }

      const isMember = this.memberData.isMember
      const memberName = isMember ? get(this.memberData, 'member.UserInfo.name') : this.memberData.name
      const memberPhone = isMember ? get(this.memberData, 'member.UserInfo.phone') : this.memberData.phone
      let depositPayment = this.depositPayment
      let depositComment = null
      if (this.depositPayment.includes('offline')) {
        depositPayment = 'offline'
        depositComment = this.depositPayment
      }
      const [, err] = await SmartCreateReservationOrder({
        shopId: this.shopId,
        reservations: this.compactReservation,
        userType: isMember ? 'member' : 'guest',
        memberId: isMember ? get(this.memberData, 'member.id', undefined) : undefined,
        userName: memberName,
        userPhone: memberPhone,
        peopleCount: this.peopleCount,
        userComment: get(this.noteData, 'comment') || undefined,
        adminComment: get(this.noteData, 'remark') || undefined,
        depositPrice: this.showDepositBlock ? this.orderDeposit.price : undefined,
        depositPaidType: this.showDepositBlock ? depositPayment : undefined,
        depositPaidTypeComment: depositComment || undefined,
        // remark: get(this.noteData, 'remark', undefined),
      })
      if (err) {
        this.loading = false
        this.$message.error(err)
        return
      }

      this.$message.success('預約成功')
      this.$emit('created')
      this.$emit('close')
      this.loading = false
    }, 300, { leading: false, trailing: true }),

  },
}
</script>

<style scoped lang="postcss">
.wrapper {
  padding: 20px !important;
  padding-left: 29px !important;
}
</style>
